

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}



/* 以上為react舊碼 */
/* start my css */
/* @font-face {
    font-family: "Noto Sans TC";
    src: url(/static/fonts/NotoSansTC-Regular.otf);
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: "Noto Sans TC";
    src: url(/static/fonts/NotoSansTC-Bold.otf);
    font-weight: 700;
    font-display: swap;
} */

body, *{
    font-family: arial, sans-serif;
    font-weight: normal;
    font-size: 14px;
    box-sizing: border-box;
    color: #757575;
}
.container{
    max-width: 600px;
    margin: 0 auto;
}
header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.3);
}
header h1 {
    display: flex;
    font-size: 17px;
    color: #448899;
    text-align: center;
    font-weight: bold;
    height: 50px;
    line-height: 25px;
    overflow-y: hidden;
    align-items: center;
    justify-content: center;
}
header button{
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    padding: 0;
    color: #666;
    line-height: 50px;
    text-decoration: none;
    background: transparent;
    border: none;
}
footer{
    text-align: center;
    
    background: #454d4a;
    padding: 40px 0 20px;
}
footer ul{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* for product comment body */
strong {
    font-size: 15px;
    color: #448899;
    font-weight: bold;
    height: 50px;
    line-height: 25px;
    overflow-y: hidden;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
}
li{
    margin: 0 20px 20px 20px;
    line-height: 1.5;
}
pd_comment_h1 {
    color: #333;
    padding: 5px 0;
    position: relative;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 2;
    margin-bottom: 10px;
}


/* =========================== */

footer li{
    margin: 0 20px 20px 20px;
}

footer li a{
    font-size: 16px;
    color: #f0f2f1;
    text-decoration: none;
}
footer p{
    font-size: 12px;
    color: #f0f2f1;
    margin-top: 10px;
}
.mainContent{
    padding-top: 50px;
    min-height: 100vh;
}

/*
 * Table Style Definition
 */

td, th, button, .row-header {
    font-weight: initial;
    object-fit: cover;
}
thead, tbody {
    text-align: center;
}
.t-white, button {
    vertical-align: middle !important;
    color: #448899; 
}
@media (min-width: 991px){
    table {
    width: 100% !important;
    max-width: 80% !important;
    }
}

p {
    line-height: 1.5;
    font-size: medium;
    font-family: arial, sans-serif;
    font-size: 14px;
}

.pd_wrap {
    max-width: 600px;
    margin: 0 auto;
}
.pd_wrap .pd_box{
    position: relative;
}
.pd_wrap .pd_img_logo{
    width: 50px;
    position: absolute;
    top: 0px;
    left: 0px;
}
.pd_wrap .pd_name{
    font-weight: bold;
    font-size: 22px;
    display: block;
    overflow: auto;
}
.pd_wrap .pd_price{
    font-size: 16px;
    color: #333;
    font-weight: bold;
}
.pd_wrap .pd_price span{
    font-size: 20px;
    font-weight: normal;
    color: red;
}
.pd_wrap .pd_comment{
    color: #333;
    padding: 5px 0;
    position: relative;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 25px;
    margin-bottom: 10px;
}
.pd_comment_short{
    color: #333;
    padding: 0px 0;
    position: relative;
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 20px;
    max-height: 40px;
    overflow: hidden;
    margin-bottom: 10px;
}
.pd_wrap .pd_comment .btn{
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: #579daf;
    color: #fff;
    font-weight: bold;
    line-height: 25px;
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
    opacity: .7;
}
.pd_wrap .pd_comment .btn:hover{
    opacity: .9;
}
.pd_wrap .pd_comment span{
    display: inline-block;
    font-weight: normal;
    color: #333;
    margin-top: 5px;
    font-size: 14px;
}
.pd_img{
    display: block;
    width: 100%;
}
.pd_info{
    padding: 20px 10px 40px 10px;
}
.pd_box.flex{
    padding: 20px;
    padding: 20px 20px 50px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    position: relative;
}
.pd_box.flex .pd_img_logo {
    width: 30px;
    position: absolute;
    top: 0px;
    left: 0px;
}
.pd_box.flex .pd_img{
    width: 120px;
}
.pd_box.flex .pd_info{
    width: calc(100% - 140px);
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.pd_name{
    color: #000;
    line-height: 1.5em;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.multiline-ellipsis{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.pd_inquiry{
    float: right;
    color: #3366FF;
    line-height: 20px;
}
.pd_history_price{
    margin-bottom: 10px;
}
.pd_history_price p{
    color: #9932cc;
    opacity: .6;
    margin-bottom: 5px;
}
.pd_price{
    color: #579daf;
    margin-bottom: 15px;
}
.set_track{
    padding: 20px;
}
.set_track input[type="number"]{
    display: block;
    width: 100%;
    height: 50px;
    line-height: 38px;
    padding: 5px 10px;
    border: 1px solid #888;
    border-radius: 6px;
    margin: 0 0 10px 0;
}
.set_track button{
    display: block;
    width: 100%;
    height: 50px;
    line-height: 48px;
    padding: 0;
    font-size: 22px;
    color: #fff;
    background-color: #448899;
    border: 1px solid #448899;
    border-radius: 6px;
    margin-top: 20px;
}
.set_track button:hover{
    background-color: #579daf;
    border: 1px solid #579daf;
}
.pdbox_btn{
    max-width: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.pdbox_btn .btn_cart{
    display: block;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    width: 100%;
    height: 32px;
    line-height: 30px;
    margin: 10px 0;
    padding: 0;
    color: #fff;
    background-color: #448899;
    border: 1px solid #448899;
    border-radius: 6px;
}
.pdbox_btn .btn_edit{
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    width: calc((100% - 10px) / 2);
    height: 32px;
    margin-right: 10px;
    line-height: 30px;
    padding: 0;
    color: #448899;
    background-color: transparent;
    border: 1px solid #448899;
    border-radius: 6px;
}
.pdbox_btn .btn_del{
    font-size: 14px;
    width: calc((100% - 10px) / 2);
    height: 32px;
    line-height: 30px;
    padding: 0;
    color: #448899;
    background-color: transparent;
    border: 1px solid #448899;
    border-radius: 6px;
}
.pdbox_btn .btn_cart:hover{
    background-color: #579daf;
    border: 1px solid #579daf;
}
.pdbox_btn .btn_del:hover, .pdbox_btn .btn_edit:hover{
    color: #579daf;
    border: 1px solid #579daf;
}
.button_section{
    position: absolute;
    bottom: 15px;
    left: 5%;
    width: 90%;
    display: flex; 
    flex-wrap: wrap;
    justify-content: space-between; 
    align-items: "center"; 
    margin-top: 20px; 
    border-top: 1px solid #ccc; 
    padding-top: 5px;
}
.button_section button{
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center; 
    font-size: 13px;
    cursor: pointer;
}

/* style for peer link button */
.peerLinkBtn {
    display: 'flex';
    height: '42px';
    justify-content: 'center';
    align-items: 'center';
    width: 'calc((100% - 10px) / 2)';
    margin: '8px';
    padding: '2px 12px';
    background-color: '#448899';
    color: '#FFFFFF';
    border: 'none';
    line-height: '10px';
    border-radius: '6px';
    opacity: '0.8';
    cursor: 'pointer'
}

/* for like button in product page */
.btn {
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
    background-color: white;
    border: none;
    color: white;
    border-radius: 5px;
}

/* =========================== */

.searchWrap{
    display: flex;
    border: 1px solid #888;
    height: 50px;
    line-height: 38px;
    padding: 5px;
    margin: 20px;
    border-radius: 6px;
    max-width: 600px;
}
.searchWrap input{
    border: none;
    height: 100%;
    width: calc(100% - 70px);
    margin-right: 10px;
    font-size: 14px;
}
.searchWrap button{
    width: 60px;
    height: 100%;
    border: none;
    border-radius: 6px;
    background-color: #eee;
}
.searchWrap button:hover, .searchWrap button:active{
    background-color: #ddd;
}
.searchList_title{
    background-color: #579daf;
    color: #fff;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 16px;
    padding: 8px 20px;
}
.searchList_title.shopee{
    background-color: #ee4d2d;
    opacity: .8;
}
.searchList_title.rakuten {
    background-color: #bf0000;
    opacity: .8;
}
.searchList_title.momo {
    background-color: #cf2c78;
    opacity: .8;
}
.searchListInner{
    position: relative;
}
.searchListInner > .pagecover{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.9;
}

.searchListInner > .pagecover.active {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.searchListInner>.pagecover.active span{
    animation: waiting 3s infinite;
}

.searchListInner>.pagecover.active span:nth-child(2) {
    animation-delay: 0.1s;
}

.searchListInner>.pagecover.active span:nth-child(3) {
    animation-delay: 0.4s;
}

.searchListInner>.pagecover.active span:nth-child(4) {
    animation-delay: 0.6s;
}

.searchListInner>.pagecover.active span:nth-child(5) {
    animation-delay: 0.8s;
}

.searchListInner>.pagecover.active span:nth-child(6) {
    animation-delay: 1s;
}

.searchReviews{
    padding: 0 30px 20px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.searchReviews img:nth-child(1){
    width: 35px;
    padding-bottom: 10px;
    cursor: pointer;
}

.searchReviews img:nth-child(2) {
    width: 35px;
    margin-left: 15px;
    cursor: pointer;
}

.searchReviews img.active{
    opacity: .5;
}

.tabTitle{
    position: sticky;
    top: 50px;
    background: #fff;
    z-index: 1;
    overflow-y: auto;
}
.tabTitle_wrap{
    display: flex; 
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    max-width: 500px;
    margin: 0 auto;
}
.tabTitle button{
    font-size: 15px;
    font-weight: bold;
    color: #333;
    background-color: #fff;
    border:  none;
}
.tabTitle button.active{
    color: #3366FF;
    text-decoration: underline;
}
.tabSubTitle_wrap{
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    max-width: 420px;
    margin: 0 auto;
}
.tabSubTitle_wrap a{
    color: #000;
    background-color: #fff;
    border:  none;
    margin: 0 10px 10px 10px;
}
#floatBox{
    position: fixed;
    z-index: 50;
    bottom: 100px;
    right: 0;
    padding: 15px 20px;
    width: 220px;
    min-height: 100px;
    height: calc(80vh - 100px);
    word-break: break-all;
    background-color: rgba(87, 157, 175, .8);
    transform: translateX(220px);
    transition: all 1s ease-out;
}
#floatBox.show{
    transform: translateX(0);
    transition: all 1s ease-in;
}
#floatBox button:after{
    display: block;
    content: "";
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: rotate(-45deg);
    position: absolute;
    top: 15px;
    left: 15px;
}
#floatBox.show button:after{
    transform: rotate(135deg);
    left: 10px;
}
#floatBox .cnt{
    color: #fff;
    line-height: 26px;
    font-size: 18px;
    height: 100%;
    overflow-y: auto;
}
#floatBox .cnt a{
    color: #eee;
    font-weight: bold;
}
#floatBox button{
    position: absolute;
    top: 0;
    left: -40px;
    width: 40px;
    height: 40px;
    border: none;
    z-index: -1;
    background-color:rgba(87, 157, 175, 1);
    color: #fff;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
#chatdiv{
    position: fixed;
    bottom: 50px;
    right: 0;
    z-index: 50;
    transform: translateX(400px);
    transition: all 1s ease-out;
}
#chatdiv >button{
    position: absolute;
    top: 0;
    left: -40px;
    width: 40px;
    height: 40px;
    border: none;
    z-index: -1;
    background-color:rgba(87, 157, 175, 1);
    color: #fff;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
#chatdiv.show{
    transform: translateX(0);
    transition: all 1s ease-in;
}
#chatdiv >button:after{
    display: block;
    content: "";
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: rotate(-45deg);
    position: absolute;
    top: 15px;
    left: 15px;
}
#chatdiv.show button:after{
    transform: rotate(135deg);
    left: 10px;
}
.gotop{
    position: fixed;
    z-index: 100;
    bottom: 54px;
    right: 10px;
    display: inline;
    width: 40px;
    height: 40px;
    font-size: 0;
    padding: 10px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #adadad;
    border-radius: 50%;
    transition: opacity .6s ease, visibility .6s;
}
.gotop:after {
    content: "";
    position: absolute;
    left: 13px;
    top: 13px;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 10px 6px;
    border-color: transparent transparent #444;
}

/* =========================== */
/* define table style */
/* =========================== */
thead tr {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .rowHeader, [scope="colgroup"] span {
    left: 0;
    position: sticky;
  }
  table {
      margin: 20px auto;
    font-family: arial, sans-serif;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    table-layout: auto;
  }
  td, th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
    min-width: 100px;
    max-width: 100px;
    background: #ffffff;
  }
  .mobileColumnGroup th {
      text-align: left;
  }
  .rowHeader {
    text-align: left;
  }
  
  thead.stickyHeader {
    position: fixed;
    z-index: 999;
    overflow-x: scroll;
    top: 0px;
  }
  thead.stickyHeader.atBottom {
    position: absolute;
    z-index: 999;
    bottom: 0px;
    left: 0px !important;
    top: initial;
    width: initial !important;
    overflow-x: clip;
  }
  thead::-webkit-scrollbar {
    height: 0px;
  }
  .horizontal-scroller {
    position: fixed;
    bottom: 0;
    height: 30px;
    overflow: auto;
    overflow-y: hidden;
  }
  .horizontal-scroller-content {
    height: 30px;
  }
  .table-container {
    padding: 2em;
  }
/* =========================== */


@keyframes waiting {
    0% {
        color: #000;
    }
    
    20% {
        color: #999;
    }
    
    40% {
        color: #fff;
    }

    60% {
        color: #222;
    }

    80% {
        color: #aaa;
    }
    
    100% {
        color: #fff;
    }
}

@media screen and (min-width: 550px) {
    .tabTitle button{
        font-size: 16px;
    }
    .button_section >p{
        width: 25%;
    }
}

@media screen and (min-width: 768px) {
    .tabTitle_wrap, .tabSubTitle_wrap.has-desktop, .container.has-desktop{
        max-width: 1200px;
    }
    .tabTitle button{
        font-size: 18px;
    }
    .pds_wrap{
        display: flex;
        flex-wrap: wrap;
    }
    .pds_wrap .pd_box{
        display: block;
        width: calc(100% / 3);
        border-bottom: none;
        margin-bottom: 10px;
    }
    .pd_box.flex .pd_img{
        width: 100%;
        margin-bottom: 10px;
    }
    .pd_box.flex .pd_info{
        width: 100%;
    }
    .pd_name{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .button_section{
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
    }
    .pd_box.flex{
        padding-bottom: 20px;
    }
    .button_section >p{
        width: 50%;
    }
}
@media screen and (min-width: 769px) {
    .registerCheck{
        max-width: 65%;
        margin: 0 auto;
    }
}
@media screen and (min-width: 1200px) {
    .pds_wrap .pd_box{
        width: calc(100% / 4);
    }
}